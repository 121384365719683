<template>
  <div class="category bj">
    <div class="title">
      <el-button
        type="primary"
        icon="el-icon-refresh"
        class="bjsearch"
        @click="Refresh"
      ></el-button>

      <el-button type="primary" @click="addclick">新增</el-button>
      <div class="seach">
        <div style="margin-right: 10px">
          <el-date-picker
            v-model="from1.create_time"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择创建日期"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <el-select v-model="from1.state" clearable placeholder="请选择状态">
            <el-option label="全部状态"
                       value=""></el-option>
            <el-option label="启用"
                       value="1"></el-option>
              <el-option label="禁用"
              value="2"></el-option>
          </el-select>
        </div>

        <el-button @click="search" type="primary" icon="el-icon-search">查询</el-button>
      </div>
    </div>
    <div class="auto-table-flex">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
      >
        <el-table-column type="index" width="100px" align="center" label="序号">
        </el-table-column>
        <el-table-column prop="title" align="center" label="模板名称">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.title) }}
          </template>
        </el-table-column>
        <el-table-column prop="create_time" align="center" label="创建时间">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.create_time) }}
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="状态">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.state == 1">启用</el-tag>
            <el-tag type="warning" v-else>禁用</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="start" align="center" label="售卖开始时间">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.start) }}
          </template>
        </el-table-column>
        <el-table-column prop="end" align="center" label="售卖结束时间">
          <template slot-scope="scope">
            {{ $empty.empty(scope.row.end) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
             v-if="scope.row.state==2"
              type="text"
              @click="handleEdit(scope.$index, scope.row)"
              >启用</el-button
            >
            <el-button
            v-else
              type="text"
              @click="handleEdit(scope.$index, scope.row)"
              >禁用</el-button
            >
            <el-button type="text" @click="selectda(scope.$index, scope.row,'编辑')"
              >编辑</el-button
            >
            <el-button type="text" @click="selectda(scope.$index, scope.row,'查看')"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <!-- 
  新增时间模版 -->
    <el-dialog
      :title="title"
      :visible.sync="centerDialogVisible"
      v-if="centerDialogVisible"
      width="700px"
      class="dialog"
      center
    >
      <div class="contents">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="120px"
          class="demo-ruleForm"
        >
          <el-form-item label="模版名称" prop="title">
            <div v-if="title=='查看'">{{ ruleForm.title }}</div>
            <div v-else>
              <el-input
                v-model="ruleForm.title"
                placeholder="请输入模版名称"
                clearable
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="售卖时间" prop="start">
            <div v-if="title=='查看'">{{ ruleForm.start+'~'+ruleForm.end }}</div>
            <div v-else>
              <el-time-picker
                is-range
                @change="timeChange"
                v-model="value1"
                range-separator="至"
                format="HH:mm"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="选择时间范围">
              </el-time-picker>
            </div>
          </el-form-item>
          <!-- <el-form-item label="售卖结束时间" prop="end">
            <div>
              <el-time-picker
              v-model="value2"
              @change="endChange"
              placeholder="结束时间">
            </el-time-picker>
            </div>
          </el-form-item> -->
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="tianku">确认</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  <script>
export default {
  name: "category",
  components: {},
  data() {
    return {
      //列表新建页面初始参数
      loading: false,
      from: {
        create_time:'',
        state:'',
        page: 1,
        pageSize: 10,
      },
      from1: {
        create_time:'',
        state:'',
        page: 1,
        pageSize: 10,
      },
      value:'',
      tableData: [],
      currentPage: 1,
      total: 0,
      //列表页面不同功能参数
      ruleForm: {
        title: "",
        start: "",
        end: ""
      },
      title: "",
      centerDialogVisible: false,
      rules: {
        title: [{ required: true, message: "请输入模版名称", trigger: "blur" }],
        start: [{ required: true, message: "请选择售卖时间", trigger: "blur" }]
      },
      options: [
        {
          value: "logistics",
          label: "集配",
        },
        {
          value: "delivery",
          label: "自提",
        },
      ],
      value1: '',
      options1: [],
      list: [],
      list1: [],
    };
  },
  created() {
      this.hqlist();
    //   this.seletlist();
  },
  methods: {
    search() {
      this.from1.page = 1;
      this.currentPage = 1;
      this.from = this.from1;
      this.hqlist();
    },
    changestore(e) {
      // console.log(e);
      this.ruleForm.name = JSON.parse(e).name;
      this.ruleForm.store_id = JSON.parse(e).id;
    },

    addclick() {
      console.log("新增");
      this.value1 = ''
      this.ruleForm = {
        title: "",
        start: "",
        end: ""
      };
      this.centerDialogVisible = true;
      this.title = "新增";
    },
    timeChange(val){
      console.log(val)
      this.ruleForm.start = this.tool.timestampToMiuse(val[0])
      this.ruleForm.end = this.tool.timestampToMiuse(val[1])
      console.log(this.ruleForm)
    },
    tianku() {
      this.$refs.ruleForm.validate(valid=>{
        if(valid){
          this.tool.debounce(()=>{
            if(this.title=='新增'){
              this.$api.general.addbSelltime(this.ruleForm).then((res) => {
                this.$message({
                  type: "success",
                  message: "添加成功",
                });
                
              });
            }else if(this.title=='编辑'){
              this.$api.general.updateSelltime(this.ruleForm).then((res) => {
                this.$message({
                  type: "success",
                  message: "已修改",
                });
                
              });
            }
            setTimeout(() => {
              this.centerDialogVisible = false
              this.Refresh();
            }, 800);
          })
        }
        
      })
      
    },
    Refresh() {
      this.from = {
        create_time:'',
        state:'',
        page: 1,
        pageSize: 10,
      };
      this.from1 = {
        create_time:'',
        state:'',
        page: 1,
        pageSize: 10,
      };
      this.currentPage = 1;
      this.hqlist();
    },
    handleSizeChange(val) {
      //   console.log(`每页 ${val} 条`);
      this.from.pageSize = val;
      this.from1.pageSize = val;
      this.hqlist();
    },
    handleCurrentChange(val) {
      this.from.page = val;
      this.from1.page = val;
      this.currentPage = val;
      this.hqlist();
    },
    handleEdit(index, row) {
      this.$confirm(
        row.state === 1
          ? "确定要禁用吗？"
          : "确定要启用吗？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        }
      )
        .then(() => {
          this.$api.general
            .selltimeStatus({
              id: row.id,
              state: row.state === 1 ? 2 : 1,
            })
            .then((res) => {
              console.log(res, "禁用或者启用");
              this.$message({
                type: "success",
                message: row.state === 1 ? "禁用成功" : "启用成功",
              });
              this.hqlist();
            });
        })
        .catch(() => {
        });
    },

    selectda(index, row,word) {
      this.title = word;
      for(let key in this.ruleForm){
        this.ruleForm[key] = row[key]
      }
      this.ruleForm.id = row.id
      if(this.ruleForm.start && this.ruleForm.end){
        let date = new Date()
        let y = date.getFullYear(),
            m = date.getMonth()+1,
            d = date.getDate(),
            h = this.ruleForm.start.split(':')[0],
            h1 = this.ruleForm.end.split(':')[0],
            m1 = this.ruleForm.start.split(':')[1],
            m2 = this.ruleForm.end.split(':')[1]

        this.value1 = [new Date(y,m,d,h,m1),new Date(y,m,d,h1,m2)]
        
      }
      console.log(this.ruleForm);
      this.centerDialogVisible = true;
    },
    hqlist() {
      this.loading = true;
      this.$api.general.selltimeList(this.from).then((res) => {
        console.log(res, "获取数据");
        this.tableData = res.data.data;
        this.total = res.data.total;
        this.loading = false;
      });
    },
    seletlist() {
      this.$api.login.unusedall().then((res) => {
        console.log(res, "获取所有可配置的自提点");
        this.list1 = res.data;
      });
      this.$api.login.logisticsunusedall().then((res) => {
        console.log(res, "获取所有可配置的城市仓");

        this.list = res.data;
      });
    },
  },
};
</script>
  <style lang="scss">
.category {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  //   background: #ffffff;
  padding: 10px;
  .title {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    display: flex;
    .seach {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      .inputs {
        width: 207px;
        margin-right: 8px;
      }
    }
    .bjsearch {
      background: #333333;
      border-color: #333333;
    }
  }

  .auto-table-flex {
    flex-grow: 1;
    overflow-y: hidden;
  }
  .fy {
    // background-color: #000;
    box-sizing: border-box;
    padding: 10px 20px;
  }
  .contents {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 23px;

    box-sizing: border-box;
    .titlname {
      font-size: 17px;
      color: #21c2ac;
      margin-bottom: 25px;
    }
    .imgs {
      display: flex;
      .imglist {
        width: 165px;
        height: 123px;
        border: 1px dashed #a4adc5;
        padding: 14px 8px;
        text-align: center;
        border-radius: 7px;
        box-sizing: border-box;
        margin-right: 16px;
        .div {
          margin-top: 20px;
          padding-bottom: 20px;
        }
        // img{
        //     width: 100%;
        //     height: 100%;
        // }
      }
    }
    .disply {
      display: flex;
      margin-bottom: 22px;
    }
    .mr {
      margin-right: 31px;
    }
    .iteminput {
      display: flex;
      align-items: center;

      .wen {
        width: 77px;
        margin-right: 24px;
        color: #000;
        font-size: 14px;
        text-align: right;
      }
      .el-input {
        width: 302px;
      }
    }
  }
}
.colors {
  color: #fa884c;
}
.times {
  margin-right: 8px;
}
// .el-picker-panel {
//   left: 1245px !important;
// }
</style>
  